import React from 'react';
import '../../styles/Footer.css';
import IonIcon from '@reacticons/ionicons';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <h2>Follow Us!</h2>
      <div className="social-icons">
        {/*}
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-facebook" className="social-icon" />
        </a>
        */}
        <a href="https://www.instagram.com/get_squirrel/" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-instagram" className="social-icon" />
        </a>
        <a href="https://www.tiktok.com/@squirrel_app" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-tiktok" className="social-icon" />
        </a>
        <a href="https://x.com/get_squirrel" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-twitter" className="social-icon" />
        </a>
        <a href="https://www.reddit.com/user/squirrel_app/" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-reddit" className="social-icon" />
        </a>
        <a href="https://www.youtube.com/channel/UCl4mggMa7nSNxmfTcz8fKqA" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-youtube" className="social-icon" />
        </a>
        {/*}
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <IonIcon name="logo-linkedin" className="social-icon" />
        </a>
        */}
      </div>
      {/*}
      <div className="legal-links">
        <a href="/terms-of-service">Terms of Service</a>
        <a href="/privacy-policy">Privacy Policy</a>
      </div>
      */}
      <p>&copy; 2024 Squirrel. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
