import React from 'react';
import Header from './components/sections/Header';
import Home from './components/pages/Home';
import Footer from './components/sections/Footer';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
};

export default App;
