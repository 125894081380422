import React from 'react';
import '../../styles/Header.css';
import whiteLogo from "../../assets/images/WhiteLogoLookingRight.png"

const Header: React.FC = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; // Reload the home page and scroll to the top
  };

  return (
    <header className="header">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src={whiteLogo} alt="Logo" className="logo-image" />
        <h1>squirrel</h1>
      </div>
    </header>
  );
};

export default Header;
