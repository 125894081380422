import React, { useEffect, useState } from 'react';
import '../../styles/Home.css';
import IonIcon from '@reacticons/ionicons';

const Home: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (window.location.hash === '#download') {
      const downloadSection = document.getElementById('download-section');
      if (downloadSection) {
        downloadSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //setSubmitted(true);
    //setEmail('');
  
    const apiUrl = 'https://yk69amw4f2.execute-api.us-east-1.amazonaws.com/emails'; 
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        // Handle successful form submission
        setSubmitted(true);
        setEmail('');
        console.log('Email successfully submitted');
      } else {
        // Handle errors if the request fails
        setSubmitted(false);
        console.error('Failed to submit email');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  
  

  return (
    <div>
      <main className="home">
        <section className="hero">
          <h2>Achieve more</h2>
          <h2>in less time</h2>
          {/* Commented out the download buttons for now */}
          {/*
          <div id="download-section" className="download-buttons">
            <div className="download-badge apple">
              <IonIcon name="logo-apple" className="download-icon" />
              <span>App Store</span>
            </div>
            <div className="download-badge google">
              <IonIcon name="logo-google-playstore" className="download-icon" />
              <span>Google Play</span>
            </div>
          </div>
          */}

          {/* Form to get early access emails */}
          <form className="signup-form" onSubmit={handleSubmit}>
          {submitted && (
            <div className="thank-you-message">
              <p className="thank-you">Success!</p>
              <p className="thank-you">We will contect you when early access</p>
              <p className="thank-you">becomes available!</p>
            </div>
          )}
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">
              Sign up for early access
              <IonIcon name="send-sharp" className="send-icon" />
            </button>
          </form>  
          

{/*}
          <p className="temp">Early access coming soon!</p>
          */}   
        </section>

        <section className="intro">
          <h2>
            <div>Great for students</div>
            <div>with ADHD</div>
          </h2>
        </section>

        <section className="features">
          <div className="feature">
            <IonIcon name="calendar-sharp" className="feature-icon" />
            <h5 className="feature-title">Automated Planning</h5>
            <p>Squirrel automatically plans your day based on your priorities and deadlines.</p>
          </div>
          <div className="feature">
            <IonIcon name="timer-sharp" className="feature-icon" />
            <h5 className="feature-title">Pomodoro Timers</h5>
            <p>A timer is assigned to each task to help maintain your focus and improve efficiency.</p>
          </div>
          <div className="feature">
            <IonIcon name="list-sharp" className="feature-icon" />
            <h5 className="feature-title">Task Feeding</h5>
            <p>Tasks are given to you one at a time to help minimize stress and lessen overwhelm.</p>
          </div>
          <div className="feature">
            <IonIcon name="notifications-sharp" className="feature-icon" />
            <h5 className="feature-title">Urgent Reminders</h5>
            <p>Optional urgent reminders that will spam you to keep you on track.</p>
          </div>
          <div className="feature">
            <IonIcon name="trending-up" className="feature-icon" />
            <h5 className="feature-title">Quick Add Task</h5>
            <p>Quick add tasks to reduce the amount of time spent planning, helping you to accomplish more throughout the day.</p>
          </div>
          <div className="feature">
            <IonIcon name="time" className="feature-icon" />
            <h5 className="feature-title">Time Tracking</h5>
            <p>Track how long you spend on each task and what you accomplish throughout each day to keep you mindful.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
